import React, {useEffect, useRef, useState} from "react"

import Layout from "src/components/layout"
import Head from "../../components/Head"

import "../contacts.scss"
import Contacts from "src/components/Contacts/Contacts"
import OfficeMap from "src/components/OfficeMap/OfficeMap"
import Start from "src/components/Start/Start"

import {useTranslation} from "react-i18next"
import {graphql, useStaticQuery} from "gatsby"
import COUNTRY from "../../constants";
import BRANDS from "../../brands"

const ClientsMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query contactPageClientsRu {
      site {
        siteMetadata {
          senderPath
          countries {
            ru {
              hotLine {
                tel
                title
              }
              contacts {
                title
                tel
                number
                schedule
              }
              officeCoordinates
            }
          }
        }
      }
    }
  `)

  const { t,i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.RU)
  }, [i18n])

  const { contacts, officeCoordinates } = data.site.siteMetadata.countries.ru

  const [email, setEmail] = useState("")
  const emailDisabled = false
  const [, setForm] = useState({
    company: "",
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const startRef = useRef()

  return (
    <div className="contacts-page">
      <Layout
        hotLine={data.site.siteMetadata.countries.ru.hotLine}
        location={location}
        country="ru"
      >
        <Head
          lang="ru"
          title={t('head.contact.title', { brand: BRANDS.ALLSPORTS.brand })}
          description={t('head.contact.description', { brand: BRANDS.ALLSPORTS.brand })}
          slug="/contact/"
        />
        <Contacts contacts={contacts} country="ru" />
        <OfficeMap officeCoordinates={officeCoordinates} />
        <Start
          type="contacts"
          startRef={startRef}
          email={email}
          emailDisabled={emailDisabled}
          setEmail={setEmail}
          setForm={sendForm}
        />
      </Layout>
    </div>
  )
}

export default ClientsMain
